<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-1 me-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <div class="logo-outer">
            <img src="/assets/images/vc-logo.png" alt="" />
          </div> -->
          <div class="header-police-logo-outer">
            <div class="header-police-logo-inner w-100">
              <img src="/assets/images/central-logo.png" class="header-goa-logo" alt="Central Data" />
              <!-- <div class="header-goa-label text-uppercase">Shrikant Shinde</div> -->
            </div>
          </div>
        </div>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <!-- <div class="menubar-outer">
          <div
            class="menubar-submeanu-outer"
            v-for="(menuItem, key) in sidebarMenu"
            :key="key"
          >
            <ul class="nav flex-column" :id="'header-' + key">
              <li class="nav-item">
                <router-link
                  v-if="menuItem.children.length == 0"
                  class="nav-link"
                  :to="'/' + menuItem.mbj7 || ''"
                >
                  <img
                    v-if="menuItem.mbj8"
                    :src="'/assets/images/new-icon/' + menuItem.mbj8"
                    class="navlink-tab-icon"
                    alt="menu"
                    width="16"
                  />
                  <img
                    v-else
                    src="/assets/images/new-icon/menu-dashboard.svg"
                    width="16"
                  />
                  {{ menuItem.label }}</router-link
                >
                <button
                  v-else-if="menuItem.children.length > 0"
                  class="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#${menuItem.label
                    .replaceAll(' ', '-')
                    .replaceAll('&', '-')}`"
                  aria-expanded="false"
                >
                  <img
                    v-if="menuItem.mbj8"
                    :src="'/assets/images/new-icon/' + menuItem.mbj8"
                    class="navlink-tab-icon"
                    alt="submenu"
                    width="16"
                  />
                  <img
                    v-else
                    src="/assets/images/new-icon/menu-dashboard.svg"
                    class="navlink-tab-icon"
                    width="16"
                  />{{ menuItem.label }}
                </button>
                <div
                  v-if="menuItem.children"
                  class="collapse"
                  data-bs-parent="#sidebarMenu"
                  :id="menuItem.label.replaceAll(' ', '-').replaceAll('&', '-')"
                >
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-for="(subMenu, index) in menuItem.children" :key="index">
                      <router-link class="nav-link" :to="'/' + subMenu.mbj7"
                        ><div class="submenu-active-span">
                          {{ subMenu.label }}
                        </div></router-link
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="menubar-outer">
          <div class="menubar-submeanu-outer" v-for="(menuItem, headIndex) in sidebarMenu" :key="headIndex">
            <ul class="nav flex-column" :id="'header-' + headIndex">
              <li class="nav-item">
                <router-link v-if="menuItem.menu" class="nav-link" :to="menuItem.link || ''"><img :src="menuItem.img"
                    class="navlink-tab-icon" alt="menu" width="16" />{{ menuItem.menu }}</router-link>
                <button v-else-if="menuItem.menuHeader" class="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse" :data-bs-target="`#${menuItem.menuHeader.replaceAll(' ', '-')}`"
                  aria-expanded="false">
                  <img :src="menuItem.img" class="navlink-tab-icon" alt="" width="16" />{{ menuItem.menuHeader }}
                </button>
                <div v-if="menuItem.sidebarSubMenu" class="collapse" data-bs-parent="#sidebarMenu"
                  :id="menuItem.menuHeader.replaceAll(' ', '-')">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-for="(subMenu, index) in menuItem.sidebarSubMenu" :key="index">
                      <router-link class="nav-link" :to="subMenu.link">{{ subMenu.submenu }}</router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-inner">
          <div>
            <img src="/assets/images/login-samrudhh-logo.png" height="50" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ApiService from "../service/ApiService";
export default {
  data() {
    return {
      // sidebarMenu: [],
      sidebarMenu: [
        {
          menu: "Dashboard",
          link: "/dashboard",
          img: "/assets/images/new-icon/Vector.svg",
        },
        {
          menu: "Users",
          link: "/users",
          img: "/assets/images/new-icon/bell.svg",
        },
        {
          menu: "Clients",
          link: "/clients",
          img: "/assets/images/new-icon/client.svg",
        },
        {
          menu: "Products",
          link: "/products",
          img: "/assets/images/new-icon/arrow.svg",
        },
        {
          menu: "End Clients",
          link: "/endClients",
          img: "/assets/images/new-icon/list.svg",
        },
        {
          menu: "Vendors",
          link: "/vendor",
          img: "/assets/images/new-icon/meeting.svg",
        },
        {
          menu: "API Settings",
          link: "/govApiSettings",
          img: "/assets/images/new-icon/report.svg",
        },
        {
          menu: "Services",
          link: "/services",
          img: "/assets/images/new-icon/document-btn.svg",
        },
        {
          menu: "SMS Sender Id",
          link: "/smssenderid",
          img: "/assets/images/new-icon/Group2.svg",
        },  
        
        // {
        //   menuHeader: "Billing",
        //   img: "/assets/images/new-icon/menu-billing.svg",
        //   sidebarSubMenu: [
        //     {
        //       submenu: "Dashboard",
        //       link: "/billing/dashboard"
        //     },
        //     {
        //       submenu: "Live Balance",
        //       link: "/billing/livebalance"
        //     },
        //     {
        //       submenu: "Tax Invoices",
        //       link: "/billing/invoice"
        //     },
        //     {
        //       submenu: "Account Statement",
        //       link: "/billing/statement"
        //     },
        //     {
        //       submenu: "Payment Receipts",
        //       link: "/billing/receipt"
        //     },
        //     {
        //       submenu: "Rate Card",
        //       link: "/billing/ratecard"
        //     },
        //   ]
        // },
        // {
        //   menu: "Search History",
        //   link: "/searchhistory",
        //   img: "/assets/images/new-icon/menu-guest.svg",
        // },
        // {
        //   menu: "Support",
        //   link: "/support/new",
        //   img: "/assets/images/new-icon/menu-campaign.svg",
        // },
        // {
        //   menuHeader: "System Settings",
        //   img: "/assets/images/new-icon/menu-billing.svg",
        //   sidebarSubMenu: [
        //     {
        //       submenu: "Batch",
        //       link: "/settings/batch"
        //     },
        //     {
        //       submenu: "Rank",
        //       link: "/settings/rank"
        //     },
        //     {
        //       submenu: "Signup Domains",
        //       link: "/settings/signupdomains"
        //     },
        //     {
        //       submenu: "Skip List",
        //       link: "/settings/skiplist"
        //     },
        //   ]
        // },
        // {
        //   menu: "Directory",
        //   link: "/directory",
        //   img: "/assets/images/new-icon/menu-campaign.svg",
        // },
        // {
        //   menu: "Scam Centers",
        //   link: "/scamcenters",
        //   img: "/assets/images/new-icon/menu-deepsearch.svg",
        // },
        // {
        //   menu: "About Deep Search",
        //   link: "/aboutdeepsearch",
        //   img: "/assets/images/new-icon/menu-campaign.svg",
        // },
      ],
    };
  },
  // mounted() {
    // this.getAllMenus();
  // },
  // ApiService: null,
  // created() {
  //   this.ApiService = new ApiService();
  // },
  // methods: {
    // getAllMenus() {
    //   this.ApiService.getAllMenus().then((data) => {
    //     if (data.status == 200) {
    //       this.sidebarMenu = data.data;
    //     } else {
    //       this.sidebarMenu = [];
    //     }
    //   }).catch( ( $error ) => {
    //       console.log( "error", $error )
    //     } )
    // },
  // },
};
</script>
