import { createRouter, createWebHistory } from 'vue-router';
import store from './store'
const routes = [


  {
    path: "/",
    name: "Home",
    exact: true,
    component: () => import("./pages/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/login",
    name: "Login",
    exact: true,
    component: () => import("./pages/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    exact: true,
    component: () => import("./pages/ForgotPassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: () => import("./pages/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    exact: true,
    component: () => import("./pages/Users.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clients",
    name: "clients",
    exact: true,
    component: () => import("./pages/Clients.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products",
    name: "products",
    exact: true,
    component: () => import("./pages/Products.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/endClients",
    name: "endClients",
    exact: true,
    component: () => import("./pages/EndClients.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendor",
    name: "vendor",
    exact: true,
    component: () => import("./pages/Vendor.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/govApiSettings",
    name: "govApiSettings",
    exact: true,
    component: () => import("./pages/GovApiSettings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/myprofile",
    name: "MyProfile",
    component: () => import("./pages/MyProfile.vue"),

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("./pages/Services.vue"),

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/smssenderid",
    name: "SMSSenderId",
    component: () => import("./pages/SenderId.vue"),

    meta: {
      requiresAuth: true,
    },
  },

  

  // {
  //   path: "/underprogress",
  //   name: "underprogress",
  //   exact: true,
  //   component: () => import( "./pages/UnderProgress.vue" ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },

  {
    path: "/:pathMatch(.*)",
    name: "notfound",
    component: () => import("./pages/NotFound"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/underprogress",
    name: "underprogress",
    exact: true,
    component: () => import("./pages/UnderProgress.vue"),
    meta: {
      requiresAuth: true,
    },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  await store.dispatch("checkLoginCredentials");
  const authenticatedUser = store.state.loggedInUserSessions.user_reference;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const checkModuleAccess = to.matched.some((record) => record.meta.checkModuleAccess);
  const moduleId = to.meta.moduleId;
  const permission_fks = [1, 11, 12];

  if (requiresAuth && !authenticatedUser) {
    next("/");
  } else {
    if (checkModuleAccess) {
      if (permission_fks.includes(moduleId)) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
});

export default router;