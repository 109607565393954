import axios from "axios";
import store from '../store'
//axios.defaults.baseURL = "http://127.0.0.1:8000";
// axios.defaults.baseURL = "http://edynamicsadmin.in";
// const StorageObjUserRef = localStorage.user_reference;
// const StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : '';
// const client = StorageObjClient.maa1 ? StorageObjClient.maa1 : '';
// const user = localStorage.user;

export default class ApiService {
  // StorageObjUserRef = localStorage.user_reference;
  // StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : "";
  // client = this.StorageObjClient.maa1 ? this.StorageObjClient.maa1 : "";
  // user = localStorage.user;

  HeaderParam = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "content-type": "application/json",
    Accept: "application/json",
  };

  // AuthenticatedUserHeader = {
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //   "content-type": "application/json",
  //   Accept: "application/json",
  //   Authorization: "Bearer " + this.StorageObjUserRef,
  //   client: this.client,
  //   user: this.user,
  // };

  commonapiall = (url, params, method, type, item) => {
    return axios(url, {
      method: method,
      headers: params,
      data: item,
    })
      .then((response) => {
        if (response.data.status === 403) {
          this.logout();
        }
        // else if (response.data.status === 401 ) {
        //  this.logoutOnSessionTimeOut({ logout_reason: response.data.logout_reason });
        // localStorage['logout_reason'] = response.data.message;
        // localStorage['error_toaster_display'] = 1;
        //location.reload();
        // this.logoutsession();
        // }
        else {
          return response.data;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  logoutsession(item) {
    let URL = "/api/u/logoutsession";
    return axios(URL, {
      method: "POST",
      headers: store.state.AuthenticatedUserHeader,
      data: item,
    })
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
  forgotpassword(item) {
    let URL = "/api/u/forgotPassword";
    // this.HeaderParam["client"] = store.state.loggedInUserSessions.clientfk;
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getLoginUserByMobileNumber(item) {
    let url = "/api/u/getLoginUserByMobileNumber";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  login(item) {
    let URL = "/api/u/userLogin";
    // this.HeaderParam["client"] = store.state.loggedInUserSessions.clientfk;
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  logout(item) {
    let URL = "/api/v1/w/userLogout";
    return axios(URL, {
      method: "POST",
      headers: store.state.AuthenticatedUserHeader,
      data: item,
    })
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
  loginCache(item) {
    // this.HeaderParam["client"] = store.state.loggedInUserSessions.clientfk;
    let URL = "/api/u/loginCache";
    return this.commonapiall(URL, this.HeaderParam, "Post", 0, item);
  }
  getalltitle(item) {
    let URL = "/api/u/common/getAllTitle";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllClients(item) {
    let URL = "/api/u/common/getAllClients";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllProducts(item) {
    let URL = "/api/u/common/getAllProducts";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllVendors(item) {
    let URL = "/api/u/common/getAllVendors";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllEndClients(item) {
    let URL = "/api/u/common/getAllEndClients";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }

  updateProfile(item) {
    let url = "/api/v1/w/updateProfile";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  systemUserList(item) {
    let url = "/api/v1/w/systemUserList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addOrUpdateUser(item) {
    let url = "/api/v1/w/addOrUpdateUser";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getClientList(item) {
    let url = "/api/v1/w/getClientList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  createUpdateclient(item) {
    let url = "/api/v1/w/createUpdateclient";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getProductList(item) {
    let url = "/api/v1/w/getProductList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  createUpdateProduct(item) {
    let url = "/api/v1/w/createUpdateProduct";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getEndClientList(item) {
    let url = "/api/v1/w/getEndClientList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  createUpdateEndClient(item) {
    let url = "/api/v1/w/createUpdateEndClient";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getVendorList(item) {
    let url = "/api/v1/w/getVendorList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  createUpdateVendor(item) {
    let url = "/api/v1/w/createUpdateVendor";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getGovAPISettingsList(item) {
    let url = "/api/v1/w/getGovAPISettingsList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  createUpdateGovAPISettings(item) {
    let URL = "/api/v1/w/createUpdateGovAPISettings";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getServiceList(item) {
    let URL = "/api/v1/w/getServiceList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  createUpdateService(item) {
    let URL = "/api/v1/w/createUpdateService";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getSenderIdList(item) {
    let URL = "/api/v1/w/getSenderIdList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  createUpdateSenderId(item) {
    let URL = "/api/v1/w/createUpdateSenderId";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }

  //end of central data API





  // TicketController API's Start
  createTicket(item) {
    let url = "/api/v1/w/createTicket";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getTicketsByStatus(item) {
    let url = "/api/v1/w/getTicketsByStatus";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getTicketMessages(item) {
    let url = "/api/v1/w/getTicketMessages";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  ticketCommunication(item) {
    let url = "/api/v1/w/ticketCommunication";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  cancelTicket(item) {
    let url = "/api/v1/w/cancelTicket";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getAllTicketsList(item) {
    let url = "/api/v1/w/getAllTicketsList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  // TicketController API's End
  // Billing API's Start
  getBillingDashboardData(item) {
    let URL = "/api/v1/w/getBillingDashboardData";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getTaxInvoices(item) {
    let URL = "/api/v1/w/getTaxInvoices";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getPaymentReceipts(item) {
    let URL = "/api/v1/w/getPaymentReceipts";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  // Billing API's End 
  getAboutInfo(item) {
    let URL = "/api/v1/w/getAboutInfo";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  addAboutInfo(item) {
    let URL = "/api/v1/w/addAboutInfo";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getComprehensiveSearchAPI(item) {
    let URL = "/api/v1/w/getComprehensiveSearchAPI";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  checkliveCall(item) {
    let URL = "/api/v1/w/checkliveCall";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Get", 0, item);
  }
  conferencecall(item) {
    let URL = "/api/v1/w/conferencecall";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getSearchHistory(item) {
    let URL = "/api/v1/w/getSearchHistory";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getSystemUserList(item) {
    let URL = "/api/v1/w/getSystemUserList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }







  /// Old API's
  // New host Registeration start
  getalltitleOpen(item) {
    let URL = "/api/getalltitle";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getCountryCodeOpen(item) {
    let URL = "/api/getCountryCode";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }

  getuserdesignationsOpen(item) {
    let url = '/api/getUserDesignations';
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getcompanytypesOpen(item) {
    let URL = "/api/getcompanytypes";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getstatesOpen(item) {
    let URL = "/api/getstates";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcitiesOpen(item) {
    let URL = "/api/getcities";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getareasOpen(item) {
    let URL = "/api/getareas";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  generateHostOtpOpen(item) {
    let URL = "/api/generateHostOtp";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  verifyHostOtpOpen(item) {
    let URL = "/api/verifyHostOtp";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  updateCompanyInfoOpen(item) {
    let URL = "/api/updateCompanyInfo";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  updateBranchInfoOpen(item) {
    let URL = "/api/updateBranchInfo";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  addHostUserOpen(item) {
    let URL = "/api/addHostUser";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getCategoriesOpen(item) {
    let URL = "/api/getCategories";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getHostBranchesOpen(item) {
    let URL = "/api/getHostBranches";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  checkUserExistsUsingMobileNumberOpen(item) {
    let URL = "/api/checkUserExistsUsingMobileNumber";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getRolesOpen(item) {
    let URL = "/api/getRoles";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  resendHostOtpOpen(item) {
    let URL = "/api/resendHostOtp";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getHostListById(item) {
    let URL = "/api/getHostListById";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  // New host Registeration end



  // admin user APIs


  //Common Controller
  getClientByDomain(item) {
    let URL = "/api/getClientByDomain";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  // User Controller
  resetPassword(item) {
    let URL = "/api/v1/resetPassword";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getUserList(item) {
    let url = "/api/v1/getUserList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getRoleList(item) {
    let url = "/api/v1/getRoleList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getRoles(item) {
    let url = "/api/v1/getRoles";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateUserPermissions(item) {
    let url = "/api/v1/updateUserPermissions";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateuserallowpermissions(item) {
    let url = "/api/v1/updateuserallowpermissions";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserById(item) {
    let url = "/api/v1/getUserById";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserUsingMobileNumber(item) {
    let URL = "/api/v1/getUserUsingMobileNumber";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  changeUserLockStatus(item) {
    let url = "/api/v1/changeUserLockStatus";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getActiveUsers(item) {
    let url = "/api/v1/getActiveUsers";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  updaterolestatus(item) {
    let url = "/api/v1/updaterolestatus";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  // Host Controller
  addOrUpdateHost(item) {
    let url = "/api/v1/addOrUpdateHost";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getHostList(item) {
    let url = "/api/v1/getHostList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getHostDetailsById(item) {
    let url = "/api/v1/getHostDetailsById";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getHostListValues(item) {
    let url = "/api/v1/getHostListValues";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserListByHostId(item) {
    let url = "/api/v1/getUserListByHostId";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }

  getHostBranchesByHostId(item) {
    let url = "/api/v1/getHostBranchesByHostId";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getBranchDetailsByBranchId(item) {
    let url = "/api/v1/getBranchDetailsByBranchId";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addOrUpdateHostBranch(item) {
    let url = "/api/v1/addOrUpdateHostBranch";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAdminUsers(item) {
    let url = "/api/v1/getAdminUsers";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }

  //   VisitorController
  addVisitor(item) {
    let url = "/api/v1/addVisitor";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateVisitorManually(item) {
    let url = "/api/v1/updateVisitorManually";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getHostVisitorHistoryList(item) {
    let url = "/api/v1/getHostVisitorHistoryList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllVisitors(item) {
    let url = "/api/v1/getAllVisitors";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getvisitorusingcontact(item) {
    let url = "/api/v1/getvisitorusingcontact";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  generateaadharotp(item) {
    let URL = "/api/v1/generateaadharotp";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getaadharcarddetails(item) {
    let URL = "/api/v1/getaadharcarddetails";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getaadhardetails(item) {
    let URL = "/api/v1/getaadhardetails";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  updateVisitorVisitingInfo(item) {
    let URL = "/api/v1/updateVisitorVisitingInfo";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getlicensedetails(item) {
    let URL = "/api/v1/getlicensedetails";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getVisitorHistroyByVisitorId(item) {
    let url = "/api/v1/getVisitorHistroyByVisitorId";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addForeignerVisitor(item) {
    let URL = "/api/v1/addForeignerVisitor";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getPassportdetails(item) {
    let URL = "/api/v1/getPassportdetails";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  updateHostVerifyStatus(item) {
    let URL = "/api/v1/updateHostVerifyStatus";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getVisitorReports(item) {
    let URL = "/api/v1/getVisitorReports";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);

  }

  //   Common Controller

  getstates(item) {
    let URL = "/api/v1/getstates";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcities(item) {
    let URL = "/api/v1/getcities";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getareas(item) {
    let URL = "/api/v1/getareas";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcompanytypes(item) {
    let URL = "/api/v1/getcompanytypes";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserDesignations(item) {
    let url = "/api/v1/getUserDesignations";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getModules(item) {
    let URL = "/api/v1/getModules";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getHostBranches(item) {
    let url = "/api/v1/getHostBranches";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }

  getCategories(item) {
    let URL = "/api/v1/getCategories";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }

  outgoingcall(item) {
    let URL = "/api/v1/outgoingcall";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCountryCode(item) {
    let URL = "/api/v1/getCountryCode";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCountryList(item) {
    let URL = "/api/v1/getCountryList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getVisitingReason(item) {
    let URL = "/api/v1/getVisitingReason";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getweekdays(item) {
    let URL = "/api/v1/getweekdays";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "GET", 0, item);
  }
  getlanguages(item) {
    let URL = "/api/v1/getlanguages";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }

  // LogController

  getTransactionalEmailLogs(item) {
    let url = "/api/v1/getTransactionalEmailLogs";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getTransactionalSmsLogs(item) {
    let url = "/api/v1/getTransactionalSmsLogs";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getIncomingCallLogs(item) {
    let url = "/api/v1/getIncomingCallLogs";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getOutgoingCallLogs(item) {
    let url = "/api/v1/getOutgoingCallLogs";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getRcsLogs(item) {
    let url = "/api/v1/getRcsLogs";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getShortUrlLogs(item) {
    let url = "/api/v1/getShortUrlLogs";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getwhatsapplogs(item) {
    let URL = "/api/v1/getwhatsapplogs";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getAadharApiLogs(item) {
    let URL = "/api/v1/getAadharApiLogs";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getDrivingLicenseApiLogs(item) {
    let URL = "/api/v1/getDrivingLicenseApiLogs";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getPassportApiLogs(item) {
    let URL = "/api/v1/getPassportApiLogs";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getWebBotLogs(item) {
    let URL = "/api/v1/getWebBotLogs";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getWebBotchatlogs(item) {
    let URL = "/api/v1/getWebBotchatlogs";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  // Billing Controller
  getHostAccountStatement(item) {
    let url = "/api/v1/getHostAccountStatement";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getHostPaymentReceipt(item) {
    let url = "/api/v1/getHostPaymentReceipt";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getHostTaxInvoices(item) {
    let URL = "/api/v1/getHostTaxInvoices";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getHostCompanyList(item) {
    let URL = "/api/v1/getHostCompanyList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  addorUpdateHostCompany(item) {
    let URL = "/api/v1/addorUpdateHostCompany";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getChargesSettings(item) {
    let URL = "/api/v1/getChargesSettings";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  addorUpdateChargesSettings(item) {
    let URL = "/api/v1/addorUpdateChargesSettings";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  changeBillingCompanyStatus(item) {
    let URL = "/api/v1/changeBillingCompanyStatus";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getHostBillingDashboardData(item) {
    let URL = "/api/v1/getHostBillingDashboardData";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getAccountStatement(item) {
    let URL = "/api/v1/getAccountStatement";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getAccountStatementAdmin(item) {
    let URL = "/api/v1/getAccountStatementAdmin";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }


  addFunds(item) {
    let URL = "/api/v1/addFunds";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getbanklist(item) {
    let URL = "/api/v1/getbanklist";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getAdminCompanyList(item) {
    let URL = "/api/v1/getAdminCompanyList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  addorUpdateAdminCompany(item) {
    let URL = "/api/v1/addorUpdateAdminCompany";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }

  getCurrentPlanDetails(item) {
    let URL = "/api/v1/getCurrentPlanDetails";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getTaxInvoiceComponent(item) {
    let URL = "/api/v1/getTaxInvoiceComponent";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  changeAdminBillingCompanyStatus(item) {
    let URL = "/api/v1/changeAdminBillingCompanyStatus";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  //Campaign Controller
  getcampaigntemplatesList(item) {
    let URL = "/api/v1/getcampaigntemplatesList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  createWhatsappBulkCampaign(item) {
    let URL = "/api/v1/createWhatsappBulkCampaign";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  createCallsBulkCampaign(item) {
    let URL = "/api/v1/createCallsBulkCampaign";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcampaign(item) {
    let URL = "/api/v1/getcampaign";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcampaignlogs(item) {
    let URL = "/api/v1/getcampaignlogs";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  campaignreport(item) {
    let URL = "/api/v1/campaignreport";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  campaigntemplates(item) {
    let URL = "/api/v1/campaigntemplates";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "get", 0, item);
  }
  savecampaign(item) {
    let URL = "/api/v1/savecampaign";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  storecampaigntemplate(item) {
    let URL = "/api/v1/storecampaigntemplate";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getCampaignFilterList(item) {
    let URL = "/api/v1/getCampaignFilterList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  addScheduleCampaign(item) {
    let url = "/api/v1/addScheduleCampaign";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getwhatsappcampaigntemplates(item) {
    let url = "/api/v1/getwhatsappcampaigntemplates";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addWhatsappCampaignTemplate(item) {
    let url = "/api/v1/addWhatsappCampaignTemplate";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getwhatsappcampaigntemplateList(item) {
    let url = "/api/v1/getwhatsappcampaigntemplateList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getcallcampaigntemplateList(item) {
    let url = "/api/v1/getcallcampaigntemplateList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addCallCampaignTemplate(item) {
    let url = "/api/v1/addCallCampaignTemplate";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getcallcampaigntemplates(item) {
    let url = "/api/v1/getcallcampaigntemplates";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getnotificationcampaigntemplates(item) {
    let url = "/api/v1/getnotificationcampaigntemplates";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addnotificationTemplate(item) {
    let url = "/api/v1/addnotificationTemplate";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getnotificationtemplateList(item) {
    let url = "/api/v1/getnotificationtemplateList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  //Cloudtelephony Controller
  getVirtualNumbersList(item) {
    let url = "/api/v1/getvirtualnumberslist";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCtSettingConfig(item) {
    let url = "/api/v1/getctsettingconfig";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getcommonctandctholdtunes(item) {
    let URL = "/api/v1/getcommonctandctholdtunes";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getdefaultctandctholdtunes(item) {
    let URL = "/api/v1/getdefaultctandctholdtunes";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  savemobilenumber(item) {
    let url = "/api/v1/savemobilenumber";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addupdatesetting(item) {
    let URL = "/api/v1/addupdatesetting";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  updatetunesconfig(item) {
    let URL = "/api/v1/updatetunesconfig";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  otherdays(item) {
    let URL = "/api/v1/otherdays";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  copysettonewday(item) {
    let URL = "/api/v1/copysettonewday";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  deletesettingset(item) {
    let URL = "/api/v1/deletesettingset";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getcttunes(item) {
    let URL = "/api/v1/getcttunes";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getctholdtunes(item) {
    let URL = "/api/v1/getctholdtunes";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  savetunesconfig(item) {
    let URL = "/api/v1/savetunesconfig";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  checkweekdayes(item) {
    let url = "/api/v1/checkweekdayes";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getcttuneslist(item) {
    let url = "/api/v1/getcttuneslist";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  addcustomtunes(item) {
    let url = "/api/v1/addcustomtunes";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  updatecustomtunes(item) {
    let url = "/api/v1/updatecustomtunes";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  cttunesStatusUpdate(item) {
    let url = "/api/v1/cttunesStatusUpdate";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  saveExistingVoterSetting(item) {
    let url = "/api/v1/saveExistingVoterSetting";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getExistingSettings(item) {
    let url = "/api/v1/getExistingSettings";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getclientuser(item) {
    let url = "/api/v1/getAdminUsers";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }

  // TemplateSettingsController
  sendtemplatealerts(item) {
    let url = "/api/v1/sendtemplatealerts";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  // SettingsController
  updateHostApprovalSetting(item) {
    let url = "/api/v1/updateHostApprovalSetting";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  updateInvoiceGenerationSetting(item) {
    let url = "/api/v1/updateInvoiceGenerationSetting";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  reportVisitor(item) {
    let url = "/api/v1/reportVisitor";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getReportReasonsList(item) {
    let url = "/api/v1/getReportReasonsList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getVisitorReportsHistory(item) {
    let url = "/api/v1/getVisitorReportsHistory";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  changeHostBranchStatus(item) {
    let url = "/api/v1/changeHostBranchStatus";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 1, item);
  }
  getVisitorWebBotHistory(item) {
    let url = '/api/u/getVisitorWebBotHistory';
    return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
  }
  webincomingmsg(item) {
    let url = '/api/u/webincomingmsg';
    return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
  }
  verifyMobileNumber(item) {
    let url = '/api/u/verifyMobileNumber';
    return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
  }
  verifyOtp(item) {
    let url = '/api/u/verifyOtp';
    return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
  }
  getCountryListBot(item) {
    let URL = "/api/u/getCountryList";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getWebCountryCode(item) {
    let URL = "/api/getCountryCode";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getVisitorsGroups(item) {
    let URL = "/api/v1/getVisitorsGroups";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getbloodgroups(item) {
    let URL = "/api/v1/getbloodgroups";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  razorPayPayment(item) {
    let url = "/api/v1/razorPayPayment";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  createHostRazorpayOrder(item) {
    let url = "/api/v1/createHostRazorpayOrder";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  generatePaytmToken(item) {
    let url = "/api/v1/generatePaytmToken";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  paytmSuccess(item) {
    let url = "/api/v1/paytmSuccess";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateUserRole(item) {
    let url = "/api/v1/updateUserRole";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCarRentalVisitorHistory(item) {
    let url = "/api/v1/getCarRentalVisitorHistory";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCurrentVisitorsGroups(item) {
    let url = "/api/v1/getCurrentVisitorsGroups";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }

  importUsers(item) {
    let url = "/api/v1/importUsers";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserImportErrorLogs(item) {
    let url = "/api/v1/getUserImportErrorLogs";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCurrentCarRentalVisitorHistory(item) {
    let url = "/api/v1/getCurrentCarRentalVisitorHistory";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  checkOutVisitor(item) {
    let url = "/api/v1/checkOutVisitor";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getListOfDebitOrCreditAccountStatement(item) {
    let url = "/api/v1/getListOfDebitOrCreditAccountStatement";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getListOfDebitOrCreditAccountStatementforAdmin(item) {
    let url = "/api/v1/getListOfDebitOrCreditAccountStatementforAdmin";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getPaymentGraph(item) {
    let url = "/api/v1/getPaymentGraph";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getPaymentGraphForAdmin(item) {
    let url = "/api/v1/getPaymentGraphForAdmin";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addFundsforHost(item) {
    let url = "/api/v1/addFundsforHost";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getInCompleteHostList(item) {
    let url = "/api/v1/getInCompleteHostList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getActiveOrSuspendedHostList(item) {
    let url = "/api/v1/getActiveOrSuspendedHostList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  search(item) {
    let URL = "api/search";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  searchDetails(item) {
    let URL = "api/searchDetails";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  userAction(item) {
    let url = "/api/v1/userAction";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getVisitorOnMonitoring(item) {
    let url = "/api/v1/getVisitorOnMonitoring";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllExpiringVisaVisitors(item) {
    let url = "/api/v1/getAllExpiringVisaVisitors";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllExpiredVisaVisitors(item) {
    let url = "/api/v1/getAllExpiredVisaVisitors";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllPoliceStation(item) {
    let url = "/api/v1/getAllPoliceStation";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllIPCSection(item) {
    let url = "/api/v1/getAllIPCSection";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getPincodeByAreaIds(item) {
    let url = "/api/v1/getPincodeByAreaIds";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCitiesByDistrictIds(item) {
    let url = "/api/v1/getCitiesByDistrictIds";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAreasByCityIds(item) {
    let url = "/api/v1/getAreasByCityIds";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  callcampaignreport(item) {
    let url = "/api/v1/callcampaignreport";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCampaignVisitorsOrHostList(item) {
    let url = "/api/v1/getCampaignVisitorsOrHostList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getwhatsappResponseTemplateList(item) {
    let url = "/api/v1/getwhatsappResponseTemplateList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addSmsCampaignTemplate(item) {
    let url = "/api/v1/addSmsCampaignTemplate";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getsmscampaigntemplates(item) {
    let url = "/api/v1/getsmscampaigntemplates";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  // getSenderIdList(item) {
  //   let url = "/api/v1/getSenderIdList";
  //   return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  // }
  changeSmsCampaignTemplateStatus(item) {
    let url = "/api/v1/changeSmsCampaignTemplateStatus";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addnotificationcampaignTemplate(item) {
    let url = "/api/v1/addnotificationcampaignTemplate";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getnotificationcampaigntemplateList(item) {
    let url = "/api/v1/getnotificationcampaigntemplateList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getTemplateBody(item) {
    let url = "/api/v1/getTemplateBody";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getWhatsappNumbers(item) {
    let url = "/api/v1/getWhatsappNumbers";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  changeChargeStatus(item) {
    let URL = "/api/v1/changeChargeStatus";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  userDeleteAction(item) {
    let url = "/api/v1/userDeleteAction";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getBotVisitingReason(item) {
    let URL = "/api/getVisitingReason";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getbotbloodgroups(item) {
    let URL = "/api/common/getbloodgroups";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getBotUserDesignations(item) {
    let url = "/api/getUserDesignations";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserDepartments(item) {
    let url = "/api/getUserDepartments";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getFirmCompanyList(item) {
    let url = "/api/v1/getFirmCompanyList";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  addFirmCompany(item) {
    let url = "/api/v1/addFirmCompany";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateFirmCompany(item) {
    let url = "/api/v1/updateFirmCompany";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateBillingCompany(item) {
    let url = "/api/v1/updateBillingCompany";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCategoriesId(item) {
    let URL = "/api/v1/getCategoriesId";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  getChargesForDashboard(item) {
    let URL = "/api/v1/getChargesForDashboard";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  logoutOnSessionTimeOut(item) {
    let URL = "/api/v1/logoutOnSessionTimeOut";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getActiveOrSuspendEmployerOrContractorList(item) {
    let URL = "/api/v1/getActiveOrSuspendEmployerOrContractorList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getInCompleteEmployerOrContractorList(item) {
    let URL = "/api/v1/getInCompleteEmployerOrContractorList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getRentAgreementList(item) {
    let URL = "/api/v1/getRentAgreementList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getAllEmpListByEmployerId(item) {
    let url = "/api/v1/getAllEmpListByEmployerId";
    return this.commonapiall(url, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  generateLawOtp(item) {
    let URL = "/api/u/generateLawOtp";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  verifyLawOtp(item) {
    let URL = "/api/u/verifyLawOtp";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "Post", 0, item);
  }
  getAllUserList(item) {
    let URL = "/api/v1/getAllUserList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getAllEmployeeList(item) {
    let URL = "/api/v1/getAllEmployeeList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getEmployerActionForSuspended(item) {
    let URL = "/api/v1/getEmployerActionForSuspended";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getPropertyDetailList(item) {
    let URL = "/api/v1/getPropertyDetailList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getPropertyOwnerList(item) {
    let URL = "/api/v1/getPropertyOwnerList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getOwnerListByPropertyId(item) {
    let URL = "/api/v1/getOwnerListByPropertyId";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getPropertyListByOwnerId(item) {
    let URL = "/api/v1/getPropertyListByOwnerId";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getOwnerRepresentativeAndAgentList(item) {
    let URL = "/api/v1/getOwnerRepresentativeAndAgentList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getAllTenantsList(item) {
    let URL = "/api/v1/getAllTenantsList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getAllFIRList(item) {
    let URL = "/api/v1/getAllFIRList";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getFIRRequestType(item) {
    let URL = "/api/v1/getFIRRequestType";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getFIRRequestSubType(item) {
    let URL = "/api/v1/getFIRRequestSubType";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  listPccApplications(item) {
    let URL = "/api/v1/listPccApplications";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getPccApplicationType(item) {
    let URL = "/api/v1/getPccApplicationType";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getAllIndustries(item) {
    let URL = "/api/getAllIndustries";
    return this.commonapiall(URL, store.state.AuthenticatedUserHeader, "POST", 0, item);
  }
  userRegistration(item) {
    let URL = "/api/u/userRegistration";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getPoliceUserDesignations(item) {
    let URL = "/api/getUserDesignations";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getPolicebloodgroups(item) {
    let URL = "/api/common/getbloodgroups";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getPoliceStationList(item) {
    let URL = "/api/getAllPoliceStation";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getUserByEncryptedId(item) {
    let URL = "/api/getUserByEncryptedId";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getEmployeeById(item) {
    let URL = "/api/getEmployeeById";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  generateemployeeaadharotp(item) {
    let URL = "/api/generateemployeeaadharotp";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getemployeeaadharcarddetails(item) {
    let URL = "/api/getaadharcarddetails";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  checkemployeeaadharmobilenumber(item) {
    let URL = "/api/checkemployeeaadharmobilenumber";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getpancarddetails(item) {
    let URL = "/api/getpancarddetails";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getdrivinglicensedetails(item) {
    let URL = "/api/getdrivinglicensedetails";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getNepalPanCardDetails(item) {
    let URL = "/api/getNepalPanCardDetails";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
}
